import { forwardRef, ReactElement, Ref, useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { useReduxDispatch } from "hooks";
import { propsAction } from "store/props";
import { AlertProps, Message } from "types";
import CloseButton from "../dialog/close-button";
import { withPlanner, WithPlannerProps } from "../hoc";
import { str } from "../../utils";

const Transition: React.ForwardRefExoticComponent<any> = forwardRef(
  (
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
  ) => <Grow ref={ref} {...props} />
);

export const Alert: React.FC<AlertProps & WithPlannerProps> = withPlanner(
  (props) => {
    const {
      title = "Информация",
      fullScreen = false,
      closeButton = true,
      actions = [{ title: "Готово", variant: "contained", color: "secondary" }],
      message = undefined,
      onClose,
      rootModal,
      planner,
    } = props;

    const dispatch = useReduxDispatch();
    const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));

    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
      if (onClose) onClose();
      planner?.timeout(() => {
        dispatch(propsAction.alert({}));
      }, 200);
    };

    useEffect(() => {
      if (message) {
        setOpen(true);
        if (rootModal)
          dispatch(
            propsAction.modal({
              modal: { [rootModal]: { show: false, params: undefined } },
            })
          );
      }
    }, [message]);

    const getText:any = (val: any) => {
      if (val === null) {
        return "";
      }
      if (Array.isArray(val)) {
        return val.map((v, _) => getText(v));
      }
      if (typeof val === "object") {
        return Object.entries(val).map(([_, v]) => getText(v))
      }
      if (typeof val === "string") {
        return <p className="alert_msg">{str.normalize(val, true)}</p>;
      }
        return "";
    };

    const getMsg = (message: Message) => {
      return Object.entries(message)
        .filter(([key, v]) => key !== "exception")
        .map(([k, val], idx) => {
          return <Box key={`alert-message-${idx}`}>{getText(val)}</Box>;
        });
    };

    return (
      <Dialog
        TransitionComponent={Transition}
        fullScreen={fullScreen || sm}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        aria-labelledby="global-alert"
        scroll="body"
        maxWidth="sm"
        open={open}
      >
        <DialogTitle id="alert-title" disableTypography>
          <Typography variant="h3">{str.normalize(title)}</Typography>
        </DialogTitle>

        {closeButton && <CloseButton close={handleClose} disabled={false} />}

        <DialogContent id="alert-content">
          <Collapse in={open} timeout={250}>
            <Box>
              <Grow
                in={open}
                style={{ transformOrigin: "50% 0 0" }}
                timeout={{ enter: 650, exit: 180 }}
              >
                <Box>{!!message && getMsg(message)}</Box>
              </Grow>
            </Box>
          </Collapse>
        </DialogContent>

        {actions.length > 0 && (
          <DialogActions id="alert-actions">
            <Grid container justifyContent="center" spacing={2}>
              {actions.map(
                (
                  {
                    title: btnTitle,
                    variant = "contained",
                    color = "secondary",
                    onAction,
                    breakpoints = { xs: 10, sm: 6 },
                    ...btnProps
                  },
                  idx
                ) => (
                  <Grid key={idx} item {...breakpoints}>
                    <Button
                      onClick={() => {
                        if (onAction) {
                          onAction();
                          handleClose();
                        } else handleClose();
                      }}
                      variant={variant}
                      color={color}
                      {...btnProps}
                      fullWidth
                    >
                      {btnTitle}
                    </Button>
                  </Grid>
                )
              )}
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    );
  }
);
