import { Box, Fade, Grid } from "@material-ui/core";
import React, { useRef } from "react";

import { str } from "../../../utils";
import { useStyles } from "./prizes.style";
import { PrizeGroup, prizes } from "./prizes.json";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImage } from "../assets";

export const SliderBox: React.FC<{ group: PrizeGroup }> = ({ group }) => {
  const sliderRef = useRef(null);
  const classes = useStyles();
  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 300,
    prevArrow: <></>,
    nextArrow: <></>,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    dotsClass: "slick-dots slick-thumb",
    appendDots: (dots) => (
      <Box>
        <ul> {dots} </ul>
      </Box>
    ),

  };

  const next = () => {
    if (sliderRef !== null) {
      // @ts-ignore
      sliderRef?.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef !== null) {
      // @ts-ignore
      sliderRef?.current.slickPrev();
    }
  };

  return (
    <Fade in timeout={350}>
      <Box className={classes.slider}>
        <Grid container alignItems="center" justifyContent={"center"}>
          <Grid item xs={1}>
            <button className="button button-prev" onClick={previous}>
              {<img src={getImage?.["arrow-prev.png"]} alt={"Назад"} />}
            </button>
          </Grid>
          <Grid item xs={10}>
            <Slider ref={sliderRef} {...settings}>
              {prizes
                .filter((f) => f.group.id === group.id)
                .map((prize) => (
                  <Box key={prize.id}>
                    <Box className={classes.prize}>
                      <Box className={"period"}>{prize.period}</Box>
                      <Box className={"description"}>
                        {prize.group.description}
                      </Box>
                      <Box className={"name"}>{str.normalize(prize.name)}</Box>
                      <Box pt={2}>
                        <img
                          src={prize.image}
                          alt={prize.name}
                          style={{ margin: "0 auto" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Slider>
          </Grid>
          <Grid item xs={1}>
            <button className="button button-next" onClick={next}>
              {<img src={getImage?.["arrow-next.png"]} alt={"Вперед"} />}
            </button>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};
