import { makeStyles, Theme } from "@material-ui/core";
import "swiper/swiper.css";

export const useStyles = makeStyles(
  ({ palette, breakpoints: { down, up }, spacing }: Theme) => ({
    btn:{
      position:"relative",
      left:"auto",
      transform:"translate3d(0,0,0)",
      bottom:"auto",
      "&:hover": {
        backgroundColor: "#E9AAA5!important",
        border: "none",
        transform: " scale(1.05)",
      },
      "&:active": {
        transform: " scale(0.95)",

      },
    },

    rules: {
      maxWidth: 1400,
      margin: "0 auto",
      "& img": {
        imageRendering: "high-quality",
      },

      "& .rules-box": {
        transition: "all 0.2s linear",
        backfaceVisibility: "hidden",
        willChange: "scale",
        filter: "blur(0)",
        "-webkit-font-smoothing": "subpixel-antialiased",

        "&:hover ": {
          "-webkit-transform": "scale(1.02)  translate3d( 0, 0, 0)",
          transform: "scale(1.02) translate3d( 0, 0, 0)",
        },
        padding: "30px 10px",
        borderRadius: "80px",
        margin: "60px auto",
        border: "2px solid #FFF",
        background: "rgba(255, 255, 255, 0.12)",
        boxShadow: "0px 0px 65.56px 0px rgba(47, 6, 12, 0.10)",
        backdropFilter: "blur(13.549099922180176px)",
        [down("sm")]: {
          width: "85%",
          margin: "30px 0",
          boxShadow: "0px 0px 25.56px 0px rgba(47, 6, 12, 0.10)",
        },
        [down("xs")]: {
          borderRadius: "40px",
        },
      },

      "& .description": {
        color: "#323E48",
        fontSize: 25,
        fontWeight: "500",
        wordWrap: "break-word",
        marginBottom: spacing(3),
        [down("md")]: {
          fontSize: 16,
        },
        [down("sm")]: {
          fontSize: 14,
        },
      },

      "& .name": {
        fontSize: 32,
        fontWeight: "800",
        wordWrap: "break-word",
        textTransform: "uppercase",
        background: "linear-gradient(140deg, #F0AD99 6.75%, #E16E7F 91.39%)",
        backgroundClip: "text",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        [down("md")]: {
          fontSize: 25,
        },
        [down("sm")]: {
          fontSize: 17,
        },
      },
    },
  })
);
