import { UseFormReturn } from "react-hook-form";
import { FilledInputProps, OutlinedInputProps } from "@material-ui/core";
import { numericInputProps } from "./props-autocomplete";
import { GenericTextInput } from "./_generic-input";
import { PhoneMask } from "./mask";

type Props = {
  form: UseFormReturn<any>;
  name: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  placeholder?: string;
  InputProps?: OutlinedInputProps | FilledInputProps;
  readOnly?: boolean;
};

export const PhoneInput: React.FC<Props> = ({
  form,
  name,
  required = true,
  disabled = false,
  label = "Телефон",
  placeholder = "+7 (999) 000 00 00",
  InputProps,
  readOnly,
}) => {
  const { register, formState } = form;

  return (
    <GenericTextInput
      register={register(name, {
        required,
        disabled,
        pattern: /(^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$) || (^\d{10}$)/i,
        setValueAs: (value: string | number) => {
          return typeof value === "string"
            ? value?.replace(/\D/g, "").substr(1, value.length)
            : value;
        },
      })}
      placeholder={placeholder}
      disabled={disabled}
      label={label}
      InputProps={{
        inputComponent: PhoneMask as any,
        inputProps: { ...(numericInputProps as any) },
        readOnly: readOnly,
        ...InputProps,
      }}
      helperText={formState.errors[name]?.message}
    />
  );
};
