import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import useReactRouter from "use-react-router";
import { scroller } from "react-scroll";
import { useReduxDispatch, useReduxSelector } from "hooks";
import { Redirect } from "react-router-dom";
import { withPlanner, WithPlannerProps } from "components/hoc";
import { useStyles } from "./promo.style";
import { getImage } from "./assets";
import PrizesSection from "./sections/prizes";
import classNames from "classnames";
import RulesSection from "./sections/rules";
import WinnersSection from "./sections/winners";

const PromoPage: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const { isAuth } = useReduxSelector((state) => state.auth);
  const { promoAviable } = useReduxSelector((state) => state.props);

  const lg = useMediaQuery(({ breakpoints: { down } }: Theme) => down("md"));
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));
  const dispatch = useReduxDispatch();
  const [pvar, setPvar] = useState<number>(0);

  const handleClick = () => {
    if (isAuth) history.push({ pathname: "profile", hash: "action" });
    else history.push({ search: "signin" });
  };

  useEffect(() => {
    if (history.location.hash === "#contest_rules") {
      planner?.timeout(() => {
        scroller.scrollTo("contest_rules", {
          ignoreCancelEvents: true,
          activeClass: "active",
          duration: 700,
          hashSpy: true,
          smooth: true,
          offset: 0,
          spy: true,
        });
        history.push({ hash: "" });
      }, 150);
    }
  }, [history.location]);

  // useEffect(() => {
  //   dispatch(
  //       propsAction.alert({
  //         title: "Уважаемые участники!",
  //         message: {
  //           info: [
  //             // eslint-disable-next-line max-len
  //             `Регистрация чеков для участия в Акции завершена 19 мая 2024 года.`,
  //           ],
  //         },
  //
  //       })
  //   );
  // }, []);

  if (typeof promoAviable === "undefined")
    return (
      <Box my={4} textAlign="center">
        <CircularProgress />
      </Box>
    );

  if (promoAviable === false) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Fade in timeout={350}>
      <Box style={{ paddingBottom: "0!important" }} className={classes.root}>
        <Box className={classes.top}>
          <Box>
            {sm && (
              <img
                src={getImage?.["bg-md.png"]}
                style={{ width: "100%" }}
                alt=""
              />
            )}
            <Box className={classes.title}>
              <img src={getImage?.["slogo.svg"]} alt="" />
            </Box>
            <Box textAlign={"center"}>
              <Button
                className={classNames(classes.btn, "animateds pulse infinite")}
                href={"/profile"}
              >
                Участвовать в&nbsp;акции
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.body}>
          <Box className={classNames(classes.leaf, classes.v1)}>
            <img src={getImage?.["1.png"]} alt="" />
          </Box>
          <PrizesSection />
          <RulesSection />
          <WinnersSection />
        </Box>
      </Box>
    </Fade>
  );
});

export default PromoPage;
