import { Fragment, useEffect } from "react";
import { parse, ParsedQuery } from "query-string";
import useReactRouter from "use-react-router";
import { usePrevious } from "react-use";
import { useReduxDispatch, useReduxSelector } from "../hooks";
import TrackEvent from "../components/GAnalitics";
import { propsAction } from "../store/props";
import { Alert } from "../components";

import {
  ChangePasswordModal,
  ForgotModal,
  forgotModalName,
  GiftPetModal,
  PetModal,
  ReadyGiftModal,
  ReceiptRulesModal,
  SignInModal,
  signInModalName,
  SignUpModal,
  signUpModalName,
  TransferPhoneModal,
} from "../modals";

import {
  MediaViewerModal,
  UploadPhotoModal,
  UploadVideoModal,
  UploadWorkModal,
} from "../pages/promo/modals";
import {UpdateModal, updateModalName} from "../modals/update";

const ModalRoot: React.FC = () => {
  const dispatch = useReduxDispatch();
  const { isAuth } = useReduxSelector((state) => state.auth);
  const { alert } = useReduxSelector((state) => state.props);

  const { location, history } = useReactRouter();
  const { pathname, search, hash } = location;
  const prevLocation = usePrevious({ location: { pathname, search, hash } });

  const openModal = (modal: string, query: ParsedQuery) => {
    dispatch(
      propsAction.modal({
        modal: { [modal]: { show: true, params: { ...query } } },
      })
    );
  };

  useEffect(() => {
    const query = parse(search);

    if (!prevLocation || prevLocation.location.search !== search) {
      dispatch(
        propsAction.modal({
          modal: {
            [signInModalName]: { show: false },
            [signUpModalName]: { show: false },
            [updateModalName]: { show: false },
            [forgotModalName]: { show: false },
          },
        })
      );

      const inviteCode = pathname?.match(/^(\/r\/)([a-zA-Z0-9]+)/)?.[2];

      if (!isAuth) {
        switch (true) {
          case "signin" in query:
            openModal(signInModalName, query);
            break;
          case "signup" in query:
            openModal(signUpModalName, query);
            break;
          case "forgot" in query:
            openModal(forgotModalName, query);
            break;
          case typeof inviteCode !== "undefined":
            if (typeof inviteCode !== "undefined") {
              history.push("/");
              openModal(signUpModalName, { r: inviteCode });
            }
            break;
          default:
        }
      }
    }

    if (
      (!prevLocation || prevLocation.location.pathname !== pathname) &&
      !("token" in query)
    ) {
      TrackEvent({
        eventCategory: "Pageview",
        eventAction: "open_page",
        eventLabel: pathname,
      });
    }
  }, [pathname, prevLocation, search]);

  return (
    <Fragment>
      <Alert {...alert} />
      <SignInModal />
      <SignUpModal />
      <ForgotModal />
      <ReceiptRulesModal />
      <PetModal />
      <GiftPetModal />
      <TransferPhoneModal />
      <ReadyGiftModal />
      <ChangePasswordModal />

      {/* Promo */}
      <UploadPhotoModal />
      <UploadVideoModal />
      <UploadWorkModal />
      <MediaViewerModal />
      <UpdateModal />
    </Fragment>
  );
};

export default ModalRoot;
