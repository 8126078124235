import { makeStyles, Theme } from "@material-ui/core";
import "swiper/swiper.css";

export const useStyles = makeStyles(
  ({ palette, breakpoints: { down, up }, spacing }: Theme) => ({
    root: {
      maxWidth: 1400,
      margin: "0 auto",

      "& .table": {
        borderSpacing: "10px",
        borderCollapse: "unset",
        "& tr, & tr > td": {
          backgroundColor: "transparent!important",
        },
        "& thead tr > th": {
          border: "none!important",
          fontSize: "20px!important",
        },
        "& tbody tr": {
          outline: "2px solid #fff",
          borderRadius: "20px",
          border: "2px solid #FFF",
          background: "rgba(255, 255, 255, 0.09)",
          boxShadow: "0px 0px 65.56px 0px rgba(47, 6, 12, 0.10)",
          backdropFilter: "blur(12.549099922180176px)",
          "& td": {
            padding: "40px 10px",
            fontSize: "20px!important",
          },
        },
      },
    },
    paginator: {
      fontSize: "18px!important",
      fontWeight: 600,
      "& .MuiPaginationItem-root":{
        padding: "12px!important",
        color: "#323E48!important",
        background: "transparent!important",
        height: "auto!important",
        width: "auto!important",
      },
      "& .Mui-selected": {
        borderRadius: 4,

        background: "linear-gradient(140deg, #F0AD99 6.75%, #E16E7F 91.39%)!important",
      },
    },
  })
);
