import {getImage} from "../assets";


export enum PrizeGroupType {
  weekly,
  monthly,
  quarterly,
}

export interface PrizeGroup {
  name: string;
  id: PrizeGroupType;
  description: string;
}

export interface Prize {
  id: number;
  name: string;
  group: PrizeGroup;
  image: string;
  period: string;
}

export const prizeGroups: PrizeGroup[] = [
  {
    id: PrizeGroupType.weekly,
    name: "Еженедельные",
    description: "Каждую неделю",
  },
  {
    id: PrizeGroupType.monthly,
    name: "Ежемесячные",
    description: "Каждый месяц",
  },
  {
    id: PrizeGroupType.quarterly,
    name: "Ежеквартальные",
    description: "Каждый квартал",
  },
];

export  const prizes: Prize[] = [
  {
    id: 1,
    period: "Апрель - Июнь",
    name: "2 сертификата Ozon <br/> Запас корма на&nbsp;1&nbsp;мес.",
    group: prizeGroups[0],
    image: getImage?.["pw1.png"],
  },
  {
    id: 2,
    period: "Июль - Сентябрь",
    name: "2 сертификата Оzon <br/> Игровой набор для питомца",
    group: prizeGroups[0],
    image: getImage?.["pw2.png"],
  },
  {
    id: 3,
    period: "Октябрь - Декабрь",
    name: "2 сертификата Оzon <br/> Увлажнитель воздуха для дома",
    group: prizeGroups[0],
    image: getImage?.["pw3.png"],
  },

  {
    id: 4,
    period: "Апрель - Июнь",
    name: "Яндекс Станция Мини&nbsp;3 ",
    group: prizeGroups[1],
    image: getImage?.["pm1.png"],
  },
  {
    id: 5,
    period: "Июль - Сентябрь",
    name: "Запас корма на&nbsp;3&nbsp;месяца",
    group: prizeGroups[1],
    image: getImage?.["pm2.png"],
  },
  {
    id: 6,
    period: "Октябрь - Декабрь",
    name: "Сертификат Ozon на&nbsp;10&nbsp;000&nbsp;рублей",
    group: prizeGroups[1],
    image: getImage?.["pm3.png"],
  },
  {
    id: 7,
    period: "Апрель - Июнь",
    name: "Сертификат на&nbsp;покупку товаров для своего питомца",
    group: prizeGroups[2],
    image: getImage?.["pq1.png"],
  },
  {
    id: 8,
    period: "Июль - Сентябрь",
    name: "Сертификат на&nbsp;обустройство дома ",
    group: prizeGroups[2],
    image: getImage?.["pq2.png"],
  },
  {
    id: 9,
    period: "Октябрь - Декабрь",
    name: "Сертификат на&nbsp;путешествие",
    group: prizeGroups[2],
    image: getImage?.["pq3.png"],
  },
];