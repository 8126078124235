import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { getWinners } from "api/promo";
import { useIntersection, useReduxDispatch } from "hooks";
import { ApiAnswerStatus, PromoUserWinner } from "types";
import { WinnerList } from "./winner-list";
import { Search } from "@material-ui/icons";
import { numericInputProps, PhoneMask } from "../../../components";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import { useStyles } from "./winners.style";

const WinnersSection: React.FC = () => {
  const classes = useStyles();
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));

  const dispatch = useReduxDispatch();

  const [inView, setInView] = useState(false);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [winners, setWinners] = useState<PromoUserWinner[]>([]);
  const history = useHistory();
  const winnersRef = useRef<HTMLDivElement>(null);

  useIntersection(winnersRef, () => {
    if (!inView) setInView(true);
  });

  const fetchWinners = async () => {
    setLoading(true);
    const { data, status } = await getWinners({
      phone: phone
        ? phone.replace(/[^0-9]/g, "").replace(/^7/g, "")
        : undefined,
    });
    setLoading(false);

    if (status === ApiAnswerStatus.SUCCESS && typeof data === typeof winners) {
      setWinners(data);
    }
  };

  useEffect(() => {
    fetchWinners();
  }, []);

  useEffect(() => {
    if (!loading && history.location.hash === "#winner") {
      setTimeout(() => {
        scroller.scrollTo("winner", {
          ignoreCancelEvents: true,
          activeClass: "active",
          duration: 700,
          hashSpy: true,
          smooth: true,
          offset: 0,
          spy: true,
        });
        history.push({ hash: "" });
      }, 1000);
    }
  }, [loading]);

  return (
    <Box pt={{ xs: 5, sm: 8 }} pb={8} id={"winner"} className={classes.root}>
      <div ref={winnersRef}>
        <Container>
          <Box>
            <Box fontSize={{ xs: 24, md: 45 }}>
              <Typography
                variant="h2"
                style={{
                  marginBottom: 20,
                  marginTop: 0,
                  fontSize: "inherit",
                  textTransform: "uppercase",
                }}
              >
                Победители
              </Typography>
            </Box>

            {!loading && winners.length === 0 && !isSearch && (
              <Box textAlign="center">
                <Typography variant="body2">Ждем первых победителей</Typography>
              </Box>
            )}

            {!loading && (winners.length > 0 || isSearch) && (
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <FormControl
                      variant="filled"
                      style={{
                        minWidth: "350px",
                        borderWidth: "2px",
                        margin: "0 auto",
                      }}
                    >
                      <FormLabel style={{ marginBottom: 10 }}>
                        Поиск по номеру телефона
                      </FormLabel>
                      <TextField
                          fullWidth={sm}
                        type={"text"}
                        value={phone}
                        variant={"filled"}
                        InputProps={{
                          inputComponent: PhoneMask as any,
                          inputProps: { ...(numericInputProps as any) },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                style={{
                                  minWidth: "40px",
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  fontSize: "30px",
                                  background: "transparent",
                                  color: "#AD7D66",
                                }}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setIsSearch(true);
                                  fetchWinners();
                                }}
                              >
                                <Search />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <WinnerList items={winners} />
                  {phone && winners.length === 0 && <Box textAlign="center">
                    Победитель с указанным номером телефона не найден
                  </Box>}
                </Grid>
              </Grid>
            )}

            <Fade in={loading}>
              <Box
                position="absolute"
                textAlign="center"
                bgcolor="rgba(255,255,255,0.5)"
                left={0}
                top={0}
                bottom={0}
                right={0}
              >
                <Box mt={6}>
                  <CircularProgress />
                </Box>
              </Box>
            </Fade>
          </Box>
        </Container>
      </div>
    </Box>
  );
};

export default WinnersSection;
