import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles(
  ({ breakpoints: { down }, spacing, palette }: Theme) => ({
    // #region Header
    header: {
      height: 94,
      width: "100%",
      position: "fixed",
      boxSizing: "border-box",
      zIndex: 1201,
      transition: "0.2s all ease-in-out !important",
      borderBottom: "1px solid rgba(255,255,255,0.001)",
      [down("sm")]: { height: 56, backgroundColor: palette.background.default },

      // '&.white': { borderBottom: `1px solid ${palette.divider}`, backgroundColor: palette.background.default },

      "&.on_scroll_header": {
        height: 80,
        backgroundColor: palette.background.default,
        [down("sm")]: { height: 56 },
      },

      "&.action": {
        borderBottom: '1px solid rgba(255,255,255,0.6)',
        backgroundColor: "rgba(255, 255, 255, 0.12)",
        backdropFilter: "blur(20.20px)!important",
      },

      "&.action.on_scroll_header": {
        backgroundColor: "rgba(255, 255, 255, 0.12)",
      },
    },
    // #endregion

    // #region Popper
    popperRoot: {
      position: "relative",
      animationDuration: "0.15s",

      "& img": { display: "block" },

      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        right: "15%",
        width: 15,
        height: 15,
        background: palette.primary.main,
        transform: "translateY(-50%) rotate(45deg)",
      },

      [down("sm")]: {
        fontSize: 14,
        fontWeight: 500,
      },
    },

    exitBtn: {
      fontSize: 18,
      textAlign: "center",
      color: palette.divider,
      padding: "10px 0 0 10px",
      cursor: "pointer",

      [down("sm")]: {
        fontSize: 12,
        fontWeight: 500,
        padding: "10px",
        color: palette.primary.main,
        textTransform: "uppercase",
      },
    },
    // #endregion

    // #region Menu
    menu: { width: "100%" },

    menuLogo: {
      display: "block",
      position: "relative",
      "& img": { display: "block" },
    },

    menuItem: {
      textAlign: "center",

      "& a": {
        display: "block",
        cursor: "pointer",
        textDecoration: "none",
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: 16,
        color: palette.text.primary,
        position: "relative",
        padding: spacing(2),
        "&:hover": {
          color: palette.primary.main,
          textDecoration: "none",
        },
      },

      "& .signin, & .signup": {
        "&.active": { color: palette.text.primary },
        "&:hover": { color: palette.primary.main },
      },

      [down("sm")]: {
        width: "100%",
        textAlign: "left",
        paddingLeft: "0px!important",
        borderBottom: `1px solid ${palette.divider}`,
        "& a": { fontWeight: 600, paddingLeft: 0, color: palette.text.primary },
      },
    },

    menuItemW: {
      "& a": {
        // color: '#fff!important',
        "&:hover": {
          // color: '#fff',
          // textDecoration: 'none',
        },
      },
    },

    icon: {
      zIndex: 1000,
      height: 55,
      width: 55,
      marginRight: -spacing(3 / 2),
      "& span": { color: palette.text.primary, "& svg": { fontSize: "1em" } },
    },

    drawerBody: {
      outline: "none",
      position: "relative",
      // height: 'calc(100vh - 56px)',
      height: "100%",
      // borderTop: `1px solid ${palette.divider}`,
    },
    // #endregion
  })
);
