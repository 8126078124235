import { lazy, Suspense } from "react";
import { Box } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import useReactRouter from "use-react-router";
import { useReduxSelector } from "hooks";
import { PrivateRoute } from "../components";
import PromoPage from "../pages/promo";

const MainPage = lazy(() => import("../pages/main"));
// const PromoPage = lazy(() => import('../pages/promo'))
const LkPage = lazy(() => import("../pages/profile"));
const SignoutPage = lazy(() => import("../pages/signout"));
const NotFoundPage = lazy(() => import("../pages/not-found"));

const Routes: React.FC = () => {
  const { location } = useReactRouter();
  const { promoAviable } = useReduxSelector((state) => state.props);

  return (
    <Suspense fallback={<Box minHeight="100vh" />}>
      <Switch location={location}>
        <Route key="index" path="/" component={MainPage} exact />
        <Route key="promo" path="/action" component={PromoPage} exact />
        <PrivateRoute key="profile" path="/profile" component={LkPage} exact />
        <PrivateRoute
          key="signout"
          path="/signout"
          component={SignoutPage}
          exact
        />
        <Route key="notfound" path="*" exact component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
