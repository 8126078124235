import { Fragment, useEffect, useState } from "react";
import { Box, Button, Collapse, Fade, Grid } from "@material-ui/core";
import { LocationOn, Pets } from "@material-ui/icons";
import useReactRouter from "use-react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  AutocompleteFetch,
  AutocompleteOption,
  AutocompleteSelect,
  DateInput,
  EmailInput,
  formLocale,
  PhoneInput,
  SelectInput,
  TextInput,
} from "components/form-control";
import { Snackbar, SubmitButton } from "components";
import { getFias, updateUser } from "api/actions";
import { ga } from "utils";
import { useReduxDispatch, useReduxSelector } from "hooks";
import { ApiAnswerStatus, Entity, FormProps } from "types";
import { propsAction } from "store/props";
import { schemas } from "./update-validation";

const UpdateForm: React.FC<{ rootModal: string }> = ({ rootModal }) => {
  const maxSteps = 4;
  const DEBUG = process.env.REACT_APP_DEBUG === "true";
  const { history } = useReactRouter();

  const { SUCCESS } = ApiAnswerStatus;

  const { modal } = useReduxSelector((state) => state.props);
  const { params } = modal[rootModal] || {};

  const dispatch = useReduxDispatch();

  const { data: user } = useReduxSelector((state) => state.auth);
  const { breedTypes, registerFocus, breeds } = useReduxSelector(
    (state) => state.props
  );

  const { notValid } = formLocale;

  const [step, setStep] = useState<number>(1);
  const [breedsItems, setBreedsItems] = useState<Entity[]>([]);

  const [breedPhone, setBreedPhone] = useState<boolean>(false);
  const [formProps, setFormProps] = useState<FormProps<any>>({
    data: undefined,
    processed: false,
    snackbar: {
      onClose: () =>
        setFormProps({
          ...formProps,
          snackbar: { ...formProps.snackbar, message: undefined },
        }),
    },
  });
  const hookForm = useForm<any>({
    defaultValues: {
      first_name: user?.first_name,
      email: user?.email,
      phone: user?.phone,
      last_name: user?.last_name,
    },
    resolver: yupResolver(schemas[step - 1]),
  });

  const {
    reset,
    formState: { isValid },
  } = hookForm;

  const setBreeds = (breed_species_id?: number) => {
    const res: Entity[] = [];
    breeds?.forEach((b) => {
      if (b.breed_species.id === breed_species_id)
        res.push({ ...b, key: b.title });
    });
    setBreedsItems(res);
  };

  const onRecaptchaChange = async (formD: FormProps<any>) => {
    const { message, status } = await updateUser({ ...formD.data });

    switch (status) {
      case SUCCESS:
        setFormProps({ ...formD, processed: false });
        dispatch(
          propsAction.alert({
            message,
            rootModal,
            onClose: () =>
              history.push({
                pathname: "/profile",
              }),
          })
        );
        ga.event({
          cat: "UpdateUser",
          action: "send_u_form",
          label: "success",
        });
        break;
      default:
        setFormProps({ ...formD, processed: false });
        dispatch(
          propsAction.alert({
            message,
            onClose: () => {
              setStep(1);
              reset();
            },
          })
        );
        ga.event({
          cat: "UpdateUser",
          action: "send_u_form",
          label: "unsuccess",
          ctx: message.error?.join(";"),
          code: status,
        });
        break;
    }
  };

  const onSubmit = (data: any) => {
    // Вытаскиваем id из объектов (select/autocomplete input)
    const props: { [x: string]: any } = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(data).map((k) => {
      props[k] = typeof data[k] === "object" ? data[k]?.id ?? data[k] : data[k];
    });

    const { city, region, area, np } = data.address || {};
    const payload = { ...props, city, region, area, np };

    if (step < maxSteps) {
      setStep((s) => s + 1);
      setFormProps({
        ...formProps,
        data: { ...formProps.data, ...payload },
        snackbar: { ...formProps.snackbar, message: undefined },
      });
    } else {
      onRecaptchaChange({
        ...formProps,
        processed: true,
        data: { ...formProps.data, ...payload },
        snackbar: { ...formProps.snackbar, message: undefined },
      });
    }
  };

  const submitFailed = (err: { [x: string]: string[] }) => {
    ga.event({
      cat: "UpdateUser",
      action: "send_u_form",
      label: "unsuccess",
      ctx: `Некоторые поля не заполнены или заполнены неверно: ${Object.entries(
        err
      )
        .map(([key]) => key)
        .join(", ")}`,
    });
    if (DEBUG) console.log("signUp Error", err);
    setFormProps({
      ...formProps,
      snackbar: { ...formProps.snackbar, message: notValid },
    });
  };

  useEffect(() => {
    switch (step) {
      case 1:
        hookForm.setFocus("last_name");
        break;
      case 2:
        hookForm.setFocus("phone");
        break;
      case 3:
        hookForm.setFocus("breed_species_id");
        break;
      case 4:
        hookForm.setFocus(
          typeof params?.r === "undefined"
            ? "nursery_recorder_id"
            : "breeder_phone"
        );
        break;
      default:
    }
  }, [step]);

  useEffect(() => {
    if (isValid)
      setFormProps({
        ...formProps,
        snackbar: { ...formProps.snackbar, message: undefined },
      });
  }, [isValid]);

  const addressFetch = async (val?: string): Promise<AutocompleteOption[]> => {
    const data = await getFias({ query: val ?? "" });
    const res = data?.suggestions
      .filter((s: any) => {
        return (
          s.data.fias_level !== "7" &&
          s.data.fias_level !== "65" &&
          !s.data.street &&
          !s.data.house
        );
      })
      .map((s: any) => ({
        value: s?.value,
        title: s?.value,
        key: s?.key,
        unrestricted_value: s?.unrestricted_value,
        city: s?.data.city_with_type,
        region: s?.data.region_with_type,
        area: s?.data.area_with_type,
        np: s?.data.settlement_with_type,
      }));
    return res;
  };

  return (
    <Fragment>
      <Box>Заполните недостающие данные</Box>
      <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} noValidate>
        <Box
          maxWidth={430}
          pt={4}
          m="0 auto"
          style={{ transition: "0.15s all ease-in-out" }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ minHeight: 400 }}
          >
            <Grid item xs={12}>
              <Box
                pb={4}
                fontSize={15}
                fontWeight={500}
                textAlign="right"
                color="#8C9297"
              >
                Шаг {step} из {maxSteps}
              </Box>

              {step === 1 && (
                <Fade in>
                  <Box>
                    <TextInput
                      name="last_name"
                      label="Фамилия"
                      form={hookForm}
                      placeholder="Пример: Иванов"
                    />

                    <TextInput
                      readOnly={true}
                      name="first_name"
                      label="Имя"
                      form={hookForm}
                      placeholder="Пример: Иван"
                    />

                    <TextInput
                      name="middle_name"
                      label="Отчество"
                      form={hookForm}
                      placeholder="Пример: Иванович"
                      options={{ required: false }}
                    />
                  </Box>
                </Fade>
              )}

              {step === 2 && (
                <Fade in>
                  <Box>
                    <PhoneInput name="phone" form={hookForm} readOnly={true} />
                    <EmailInput name="email" readOnly={true} form={hookForm} />
                    <AutocompleteFetch
                      label="Населенный пункт"
                      placeholder="Например: Москва"
                      name="address"
                      fetch={addressFetch}
                      form={hookForm}
                      optionIcon={
                        <LocationOn fontSize="inherit" style={{ padding: 0 }} />
                      }
                    />
                  </Box>
                </Fade>
              )}

              {step === 3 && (
                <Fade in>
                  <Box>
                    <SelectInput
                      name="breed_species_id"
                      label="Питомец"
                      items={breedTypes}
                      form={hookForm}
                      onChange={setBreeds}
                    />

                    <AutocompleteSelect
                      name="breed_id"
                      label="Порода"
                      defaultOptions={breedsItems}
                      placeholder="Не выбрано"
                      optionIcon={<Pets />}
                      disabled={breedsItems.length === 0}
                      form={hookForm}
                    />

                    <TextInput
                      name="pet_name"
                      label="Кличка"
                      form={hookForm}
                      placeholder="Пример: Лаки"
                    />

                    <DateInput
                      name="pet_birthday_at"
                      label="Дата рождения"
                      min={new Date("1980-01-01")}
                      form={hookForm}
                    />
                  </Box>
                </Fade>
              )}

              {step === 4 && (
                <Fade in>
                  <Box>
                    <SelectInput
                      name="nursery_recorder_id"
                      label="Вы приобрели питомца у заводчика?"
                      items={[
                        { id: 1, key: "1", title: "Да" },
                        { id: 2, key: "0", title: "Нет" },
                      ]}
                      onChange={(id) => setBreedPhone(id === 1)}
                      form={hookForm}
                    />
                    <Collapse
                      in={breedPhone || typeof params?.r !== "undefined"}
                    >
                      <Box
                        className={`animated ${
                          breedPhone || typeof params?.r !== "undefined"
                            ? "zoomIn"
                            : "zoomOut"
                        } faster`}
                      >
                        <PhoneInput
                          name="breeder_phone"
                          label="Телефон заводчика"
                          required={breedPhone}
                          form={hookForm}
                          disabled={
                            !breedPhone && typeof params?.r === "undefined"
                          }
                        />
                      </Box>
                    </Collapse>
                  </Box>
                </Fade>
              )}
            </Grid>

            <Grid item xs={12}>
              <Snackbar {...formProps.snackbar} />
            </Grid>

            <Grid item xs={12}>
              <Box mt={5}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} sm={5}>
                    {step > 1 && (
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          if (step > 1) setStep(step - 1);
                        }}
                      >
                        Назад
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={5}>
                    <SubmitButton
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disabled={formProps.processed}
                      processed={formProps.processed}
                      title={step < maxSteps ? "Далее" : "Подтвердить"}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Fragment>
  );
};

export default UpdateForm;
