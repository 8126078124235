import { makeStyles, Theme } from "@material-ui/core";
import "swiper/swiper.css";

export const useStyles = makeStyles(
  ({ palette, breakpoints: { down, up }, spacing }: Theme) => ({
    root: {
      maxWidth: "1400px",
      margin: "0 auto",
      padding: "0 40px",
      [down("sm")]: {
        padding: "0 0px",
      },
    },

    tabs: {
      paddingTop:20,
    },
    tab: {
      "&.MuiTab-textColorInherit[aria-selected^='true']":{
        background:"transparent!important",
      },
      "& span": {
        transition: "all 0.15s ease-in-out",
        fontSize: "36px",
        fontWeight: "900!important",
        color: "rgba(50, 62, 72, 0.50)",
        [down("md")]: {
          fontSize: "25px!important",
        },
        "&:hover": {
          background: "linear-gradient(140deg, #F0AD99 6.75%, #E16E7F 91.39%)",
          backgroundClip: "text",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
          transform: "scale(1.05)",
        }
      },
      "&.Mui-selected span": {
        background: "linear-gradient(140deg, #F0AD99 6.75%, #E16E7F 91.39%)",
        backgroundClip: "text",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      },

      justifyContent: "center!important",

    },

    slider: {
      position: "relative",
      "& .slick-dots": {
        position: "relative",
        marginTop: "-60px!important",
      },
      "& .slick-dots ul": {
        padding: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& li": {
          transition: "all 0.15s ease-in-out",
          margin: "0 4px",
          display: "flex",
          width: "20px",
          height: "20px",
          borderRadius: "20px",
          background: "#CCC1B3",
          border: "1px blue #CCC1B3",
          "&:hover": {
            transform: "scale(1.2)",
          },
          "& button:before": {
            color: "transparent",
          }
        },
      },

      "& .slick-dots li.slick-active": {
        background:
          "linear-gradient(140deg, #F0AD99 6.75%, #E16E7F 91.39%) #F0AD99",
      },

      "& .slick-dots ul li:nth-child(1)": {
        width: "24px!important",
        height: "24px!important",
      },

      "& .slick-dots ul li:nth-child(3)": {
        width: "14px!important",
        height: "14px!important",
      },

      "& button": {
        background: "transparent",
        zIndex: 10,
        border: "none",
        outline: "none",
        cursor: "pointer",
        transition: "all 0.1s linear",
        backfaceVisibility: "hidden",
        willChange: "scale",
        filter: "blur(0)",
        "-webkit-font-smoothing": "subpixel-antialiased",
        "&:hover": {
          transform: "scale(1.05)",
        },
        "&:active": {
          transform: "scale(0.95)",
        },

        "&.button-prev": {
          left: "0",
        },
        "&.button-next": {
          right: "0",
        },
        "& img": {
          maxWidth: "100%",
        },
      },
    },

    prize: {
      width: "85%",
      maxWidth: "800px",
      padding: "30px 10px",
      borderRadius: "80px",
      margin: "40px auto",
      border: "2px solid #FFF",
      background: "rgba(255, 255, 255, 0.12)",
      boxShadow: "0px 0px 45.56px 0px rgba(47, 6, 12, 0.10)",
      backdropFilter: "blur(13.549099922180176px)",
      overflow:"visible",

      "& img": {
        transition: "all 0.2s linear",
        backfaceVisibility: "hidden",
        willChange: "scale",
        filter: "blur(0)",
        "-webkit-font-smoothing": "subpixel-antialiased",
      },
      "&:hover img":{
        transform: "scale(1.05)",

      },

      [down("sm")]: {
        boxShadow: "0px 0px 25.56px 0px rgba(47, 6, 12, 0.10)",
        padding: "30px 10%",
        width: "70%",
        margin: "30px auto",
      },
      [down("xs")]: {
        boxShadow: "0px 0px 12.56px 0px rgba(47, 6, 12, 0.10)",
        marginBottom:"50px",
        borderRadius: "40px",
      },
    },

    prizeMain:{
      [down("sm")]: {
        boxShadow: "0px 0px 25.56px 0px rgba(47, 6, 12, 0.10)",
        padding: "30px 10%",
        width: "100%",
        margin: "30px auto",
      },
    },
    prizes: {
      position: "relative",
      borderRadius: "30px",
      textAlign: "center",

      "& .titlemd": {
        marginTop: 40,
        fontSize: "inherit",
        textTransform: "uppercase",
        marginBottom: 0,
        background: "linear-gradient(140deg, #F0AD99 6.75%, #E16E7F 91.39%)",
        backgroundClip: "text",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      },
      "& .period": {
        color: "#323E48",
        fontSize: 34,
        fontWeight: "700",
        wordWrap: "break-word",
        [down("md")]: {
          fontSize: 30,
        },
        [down("sm")]: {
          fontSize: 17,
        },
      },
      "& .description": {
        color: "#323E48",
        fontSize: 24,
        fontWeight: "500",
        wordWrap: "break-word",
        marginBottom: spacing(3),
        [down("md")]: {
          fontSize: 16,
        },
        [down("sm")]: {
          fontSize: 14,
        },
      },

      "& .name": {
        color: "#323E48",
        fontSize: 26,
        fontWeight: "700",
        wordWrap: "break-word",
        [down("md")]: {
          fontSize: 30,
        },
        [down("sm")]: {
          fontSize: 17,
        },
      },
    },
  })
);
