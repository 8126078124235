import {
  Box, Container,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { SetStateAction } from "react";
import { useStyles } from "./prizes.style";
import { prizeGroups, PrizeGroupType } from "./prizes.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImage } from "../assets";
import { SliderBox } from "./prizes.slider";
import classNames from "classnames";

const TabPanel: React.FC<{
  value: number;
  index: number;
  children: React.ReactNode;
}> = ({ index, value, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const PrizesSection: React.FC = () => {
  const classes = useStyles();

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));
  const [tab, setTab] = React.useState<PrizeGroupType>(PrizeGroupType.weekly);

  const handleChange = (
    event: any,
    newValue: SetStateAction<PrizeGroupType>
  ) => {
    setTab(newValue);
  };

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 34, md: 45 }}  pt={{ xs: 2, sm: 6 }}>
        <Typography
          variant="h2"
          style={{
              marginBottom: 10,
            marginTop: 0,
            fontSize: "inherit",
            textTransform: "uppercase",
          }}
        >
          Призы
        </Typography>
      </Box>
      <Container>
      <Box fontSize={{ xs: 20, sm: 20, md: 36 }}>
        <Grid
          container
          alignItems={"center"}
          justifyContent="center"
          spacing={2}
        >
          {!md && (
            <Grid item xs={12}>
              <Tabs
                className={classes.tabs}
                variant={"fullWidth"}
                value={tab}
                onChange={handleChange}
                aria-label="Призы"
              >
                {prizeGroups.map((group) => (
                  <Tab
                    className={classes.tab}
                    key={group.id}
                    label={group.name}
                  />
                ))}
              </Tabs>
            </Grid>
          )}
          {!md ? (
            <Grid item xs={12} className={classes.prizes}>
              {prizeGroups.map((group) => (
                <TabPanel
                  value={tab}
                  index={group.id}
                  key={`section-${group.id}`}
                >
                  <SliderBox group={group} />
                </TabPanel>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.prizes}>
              {prizeGroups.map((group) => (
                <Box key={`section-${group.id}`} pb={4}>
                  <Box fontSize={{ xs: 24, md: 45 }}>
                    <Typography className={"titlemd"} variant="h2">
                      {group.name}
                    </Typography>
                  </Box>
                  <SliderBox group={group} />
                </Box>
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            <Box fontSize={{ xs: 24, md: 45 }}>
              <Typography
                variant="h2"
                style={{
                  marginTop: 40,
                  fontSize: "inherit",
                  textTransform: "uppercase",
                  marginBottom: 0,
                }}
              >
                Главный приз
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className={classNames(classes.prize, classes.prizeMain)}
              style={{ maxWidth: "100%", padding: 0 }}
            >
              <Box pt={2}>
                <img
                  src={getImage?.["pmain.png"]}
                  alt={"Главный приз"}
                  style={{ margin: "0 auto", maxWidth: "100%" }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      </Container>
    </Box>
  );
};

export default PrizesSection;
