import {
  Box, Button,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { str } from "utils";
import { useReduxSelector } from "hooks";
import { getImage } from "../assets";
import { useStyles } from "./rules.style";
import { useStyles as useStylesPromo } from "./../promo.style";
import Slider, { Settings } from "react-slick";
import React, { useRef } from "react";
import classNames from "classnames";

const RulesSection: React.FC = () => {
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));
  const classes = useStyles();
  const classesPromo = useStylesPromo();
  const { promoRulesSrc = "#", DOC } = useReduxSelector((s) => s.props);

  const sliderRef = useRef(null);
  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 300,
    centerMode: true,
    prevArrow: <></>,
    nextArrow: <></>,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    dotsClass: "slick-dots slick-thumb",
    appendDots: (dots) => (
      <Box>
        <ul> {dots} </ul>
      </Box>
    ),
    customPaging: (i) => <div style={{}}></div>,
  };

  const rules = [
    {
      src: getImage?.["r1.png"] ?? "",
      title: "Покупайте",
      description: "корм ТМ&nbsp;AlphaPet®",
    },
    {
      src: getImage?.["r2.png"] ?? "",
      title: "Регистрируйте",
      description: "чеки на сайте",
    },
    {
      src: getImage?.["r3.png"] ?? "",
      title: "Выигрывайте",
      description: "ценные призы",
    },
  ];

  return (
    <Box
      pt={{ xs: 2, sm: 8 }}
      pb={{ xs: 2, sm: 8 }}
      id="rules"
      className={classes.rules}
    >
      <Box fontSize={{ xs: 24, md: 45 }}>
        <Typography
          variant="h2"
          style={{
            marginBottom: 10,
            marginTop: 0,
            fontSize: "inherit",
            textTransform: "uppercase",
          }}
        >
          Правила
        </Typography>
      </Box>
      <Container>
        <Grid container spacing={md ? 2 : 4} justifyContent={"space-around"}>
          {md ? (
            <Grid item xs={12}>
              <Slider ref={sliderRef} {...settings}>
                {rules.map(({ src, title, description }, idx) => (
                  <Box style={{ marginRight: 40 }}>
                    <Box key={`rule-${idx}`} className={"rules-box"}>
                      <Grid
                        container
                        justifyContent={sm ? "flex-start" : "center"}
                      >
                        <Grid item xs={12}>
                          <Box
                            mb={4}
                            mt={2}
                            mx="auto"
                            maxWidth="90%"
                            textAlign="center"
                          >
                            <img
                              draggable={false}
                              src={src}
                              alt=""
                              height="auto"
                              style={{
                                maxWidth: "100%",
                                margin: "0 auto",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box position="relative" mx="auto" maxWidth="90%">
                            <Box
                              position="relative"
                              pb={{ xs: 0.5, sm: 1 }}
                              zIndex={2}
                              fontSize={{ xs: 16, sm: 18, md: 25 }}
                              fontWeight={600}
                              textAlign={"center"}
                              className={"name"}
                            >
                              {str.normalize(title)}
                            </Box>
                            <Box
                              fontWeight={400}
                              fontSize={14}
                              textAlign={"center"}
                              className={"description"}
                            >
                              {str.normalize(description)}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Grid>
          ) : (
            <>
              {rules.map(({ src, title, description }, idx) => (
                <Grid key={`rule-${idx}`} item xs={10} sm={4} md={4} lg={4}>
                  <Box className={"rules-box"}>
                    <Grid
                      container
                      justifyContent={sm ? "flex-start" : "center"}
                    >
                      <Grid item xs={12}>
                        <Box
                          mb={4}
                          mt={2}
                          mx="auto"
                          maxWidth="90%"
                          textAlign="center"
                        >
                          <img
                            draggable={false}
                            src={src}
                            alt=""
                            height="auto"
                            style={{
                              maxWidth: "100%",
                              margin: "0 auto",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box position="relative" mx="auto" maxWidth="90%">
                          <Box
                            position="relative"
                            pb={{ xs: 0.5, sm: 1 }}
                            zIndex={2}
                            fontSize={{ xs: 16, sm: 18, md: 25 }}
                            fontWeight={600}
                            textAlign={"center"}
                            className={"name"}
                          >
                            {str.normalize(title)}
                          </Box>
                          <Box
                            fontWeight={400}
                            fontSize={14}
                            textAlign={"center"}
                            className={"description"}
                          >
                            {str.normalize(description)}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </>
          )}
          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <Button
                  className={classNames(classesPromo.btn, classes.btn)}
                  rel="noreferrer"
                  target="_blank"
                  download={`${DOC?.rules_promo?.title?.replace(/\s/gi, "_")}.${
                      DOC?.rules_promo?.primary_src?.match(/[a-z]+?$/i)?.[0]
                  }`}
                  href={ DOC?.rules_promo?.primary_src ?? ""}
              >
                Полные правила акции
              </Button>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RulesSection;
