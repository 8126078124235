import * as yup from 'yup'
import moment from 'moment'
import { validation } from 'components/form-control'

export const schemas = [
  validation({
    last_name: yup
      .string()
      .required()
      .min(2)
      .max(30)
      .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, 'Только буквенные символы'),
    first_name: yup
      .string()
      .required()
      .min(2)
      .max(30)
      .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, 'Только буквенные символы'),
    middle_name: yup.string().matches(/^[а-яёА-ЯЁa-zA-Z\s-]{2,30}$/i, {
      message: 'Только буквенные символы',
      excludeEmptyString: true,
    }),
  }),

  validation({

    address: yup
      .object()
      .typeError('Выберите регион и населенный пункт')
      .required('Выберите регион и населенный пункт')
      .test('city-or-np', 'Выберите регион и населенный пункт', (a) => a?.city || a?.np),
  }),

  validation({
    breed_species_id: yup
      .number()
      .typeError('Выберите питомца из списка')
      .required('Выберите питомца из списка')
      .positive()
      .integer(),
    breed_id: yup
      .object()
      .typeError('Выберите породу из списка')
      .required('Выберите породу из списка')
      .test('number', 'Выберите породу из списка', (i) => i?.id),
    pet_name: yup
      .string()
      .required()
      .min(2)
      .max(30)
      .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, 'Только буквенные символы'),
    pet_birthday_at: yup
      .string()
      .required()
      .test({ test: (val, _) => val?.toLowerCase() !== 'invalid date', message: 'Введена некорректная дата' })
      .test({
        test: (val, _) => moment(val).isAfter('1980-01-01') && moment(val).isBefore(new Date()),
        message: 'Дата вне допустимого интервала',
      }),
  }),

  validation({
    nursery_recorder_id: yup.number().integer(),
    breeder_phone: yup
      .string()
      .matches(/^\d{10}$/i, { message: 'Указан некорректный номер телефона', excludeEmptyString: true }),
  }),
]
