import { makeStyles, Theme } from "@material-ui/core";
import { getImage } from "./assets";

export const useStyles = makeStyles(
  ({ palette, breakpoints: { down, up }, spacing }: Theme) => ({
    root: {
      "& img": {
        display: "block",
        maxWidth: "100%",
        pointerEvents: "none",
      },
      background: " #F3ECE3",
    },
    top: {
      marginTop: "-93px",
      height: "100vh",
      position: "relative",
      background: `url(${getImage?.["bg.png"] ?? ""}) bottom center no-repeat`,
      backgroundSize: "cover",

      [down("sm")]: {},
      [down("xs")]: {
        height: "auto",
      },
    },

    body: {
      color: "#323E48",
    },
    title: {
      color: "#323E48",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 95,
      textAlign: "center",
      fontSize: 55,
      fontWeight: 900,
      lineHeight: 1.4,
      textShadow: "0px 0px 18px #FFF",
      "& img": {
        maxWidth: "835px",
        width: "70%",
        marginTop: 20,
        display: "inline-block",
      },
    },

    btn: {
      border: "none",
      position: "absolute",
      bottom: 80,
      left: "50%",
      fontSize: 22,
      transform: "translateX(-50%)",
      color: "#323E48",
      textTransform: "uppercase",
      fontWeight: 600,
      borderRadius: 12,
      background: "linear-gradient(110deg, #F0AD99 12.27%, #E16E7F 95.89%)",
      padding: "20px 30px",
      transition: "0.15s",

      backfaceVisibility: "hidden",
      willChange: "scale",
      filter: "blur(0)",
      "-webkit-font-smoothing": "subpixel-antialiased",


      "&:hover": {
        backgroundColor: "#E9AAA5!important",
        border: "none",
        transform: "translateX(-50%) scale(1.05)",
      },
      "&:active": {
        transform: "translateX(-50%) scale(0.95)",
      },
      [down("sm")]: {
        fontSize: 18,
      },
      [down("xs")]: {
        fontSize: 14,
      },
    },
    leaf: {
      top:900,
      position: "absolute",
      zIndex: 0,
    },
    v1: {right: 0,},
  })
);
