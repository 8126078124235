import { Cash, RegisterUser } from "../types";

import {
  apiFetchData,
  fetchPrivateAPIToken,
  signout as signoutService,
  withPrivateAPIToken,
  withPublicAPIToken,
} from "./service";
import { TYookassaDataCard } from "../components/yookassa/yookassaType";

const { REACT_APP_DADATA_TOKEN } = process.env;

const toFormData = (props: any): FormData => {
  const data = new FormData();
  Object.keys(props).forEach((key) => {
    if (typeof props[key] !== "undefined") {
      data.append(key, props[key]);
    }
  });
  return data;
};

export const signin = async (username: string, password: string) =>
  fetchPrivateAPIToken(username, password);
export const signout = async (): Promise<void> => signoutService();

export const lkInfo = async () =>
  withPrivateAPIToken({
    url: "/lk/get",
    method: "get",
  });

export const constants = async () =>
  withPublicAPIToken({
    url: "/constant",
    method: "get",
  });

export const auth = async () =>
  withPrivateAPIToken({
    url: "/user/owner/info",
    method: "get",
  });

export const checkUniqEmail = (props: any) =>
  withPublicAPIToken({
    url: "/user/exists",
    method: "post",
    data: props,
  });

export const checkUniqEmailAuth = (props: any) =>
  withPrivateAPIToken({
    url: "/user/exists/auth",
    method: "post",
    data: props,
  });

export const checkUniqPhone = (props: any) =>
  withPublicAPIToken({
    url: "/user/exists",
    method: "post",
    data: props,
  });

export const signup = (props: RegisterUser) => {
  // const invite = ls.get("invite");
  return withPublicAPIToken({
    url: "/user/owner/register",
    method: "post",
    data: { ...props },
  });
};

export const forgotPasswordPhone = async (data: {
  phone: string;
  recaptcha: string;
}) =>
  withPublicAPIToken({
    url: "/user/owner/forgot/password/phone",
    method: "post",
    data,
  });

export const changePassword = async (data: {
  password: string;
  password_confirmation: string;
}) =>
  withPrivateAPIToken({
    url: "/user/owner/password",
    method: "post",
    data,
  });

export const subscribeChange = async (data: any) =>
  withPrivateAPIToken({
    url: "/user/owner/update",
    method: "post",
    data,
  });

export const updateUser = async (data: any) =>
  withPrivateAPIToken({
    url: `/user/owner/update`,
    method: "post",
    data,
  });

export const post = async (url: string, data: any) =>
  withPrivateAPIToken({
    url,
    method: "post",
    data,
  });

export const get = async (url: string, params?: any) =>
  withPublicAPIToken({
    url,
    method: "get",
    params,
  });

export const phoneList = async () =>
  withPrivateAPIToken({ url: "/user/owner/phone_list/get", method: "get" });

export const bankCardList = async () =>
  withPrivateAPIToken({ url: "/user/owner/bank_card/get", method: "get" });

export const getBankCardLink = async () =>
  withPrivateAPIToken({
    url: "/user/owner/bank_card/create/session",
    method: "get",
  });

export const addPhone = async (props: any) =>
  withPrivateAPIToken({
    url: "/user/owner/phone_list/create",
    method: "post",
    data: toFormData(props),
  });

export const createCash = async (props: Cash) =>
  withPrivateAPIToken({
    url: "/user/owner/cash/create",
    method: "post",
    data: toFormData(props),
  });

export const deletePhone = async (id: number) =>
  withPrivateAPIToken({
    url: `/user/owner/phone_list/${id}`,
    method: "delete",
  });

export const deleteBankCard = async (id: number) =>
    await withPrivateAPIToken({ url: `/user/owner/bank_card/${id}`, method: "delete" });

export const getCashAviableWay = async (id: number) =>
  withPrivateAPIToken({
    url: `/user/owner/cash/${id}/aviable_way`,
    method: "get",
  });

export const updateCash = async ({ id, ...props }: { id: number } & Cash) =>
    withPrivateAPIToken({
        url: `/user/owner/cash/${id}/update`,
        data: toFormData(props),
        method: "post",
    });

export const storeCash = async (data: {
    cash_type: 'bank_card'
    owner_bank_card_id: number | undefined
}) =>
    await withPrivateAPIToken({
        url: '/user/owner/cash/create',
        method: 'post',
        data: { ...data },
    })

export const inviteOwner = async (invite_hash: string | string[] | null) =>
  withPublicAPIToken({
    url: `/user/owner/invite/info/${invite_hash}`,
    method: "get",
  });

export const upload = async (props: any) =>
  withPrivateAPIToken({
    url: "/upload",
    method: "post",
    data: toFormData(props),
  });

export const receiptRegister = async (props: any) =>
  withPrivateAPIToken({
    url: "/user/owner/check_register",
    method: "post",
    data: toFormData(props),
  });

export const receiptUpload = async (props: { image: Blob }) =>
  withPrivateAPIToken({
    url: "/user/owner/check_upload",
    method: "post",
    data: toFormData(props),
  });

export const addPet = async (props: any) =>
  withPrivateAPIToken({
    url: `/user/owner_pet/add`,
    method: "post",
    data: toFormData(props),
  });

export const updatePet = async (props: any, id: number) =>
  withPrivateAPIToken({
    url: `/user/owner_pet/${id}/update`,
    method: "post",
    data: toFormData(props),
  });

export const deletePet = async (id: number) =>
  withPrivateAPIToken({
    url: `/user/owner_pet/${id}`,
    method: "delete",
  });

export const requestBuyerPetPrize = async (address: string) =>
  withPrivateAPIToken({
    url: "/user/owner/request_owner_pet_priz",
    method: "post",
    data: { address },
  });

export const socialUpdate = async (data: any) =>
  withPrivateAPIToken({
    url: "/user/owner/social_update",
    method: "post",
    data,
  });

export const getFias = async (data: { query: string }): Promise<any> =>
  apiFetchData({
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    headers: { Authorization: `Token ${REACT_APP_DADATA_TOKEN}` },
    method: "post",
    data: JSON.stringify({
      ...data,
      lang: "ru",
      locations: [
        {
          country_iso_code: "RU",
        },
      ],
    }),
  });

export const getBankCards = async () =>
  await withPrivateAPIToken({
    url: "/user/owner/bank_card/get",
    method: "get",
  });

export const storeBankCard = async (data: { widget: TYookassaDataCard }) =>
  await withPrivateAPIToken({
    url: "/user/owner/bank_card/widget/store",
    method: "post",
    data: { ...data },
  });
