import useReactRouter from 'use-react-router'
import { useEffect } from 'react'
import { Modal } from 'components'
import TrackEvent from 'components/GAnalitics'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import UpdateForm from './update-form'

export const updateModalName = 'UpdateModal'

export const UpdateModal: React.FC = () => {
  const { history } = useReactRouter()
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { isAuth } = useReduxSelector((state) => state.auth)
  const { show = false } = modal[updateModalName] || {}

  const handleClose = () => {
    dispatch(propsAction.modal({ modal: { [updateModalName]: { show: false } } }))
  }

  useEffect(() => {
    if (show && !isAuth) history.push('/')
  }, [show, isAuth])

  return (
    <Modal
      open={show}
      maxWidth="sm"
      closeButton={false}
      title="Данные владельца"
      name={updateModalName}
      onCloseDialog={() => {
        history.push({ search: '' })
        handleClose()
      }}
      onOpen={() => TrackEvent({ eventCategory: 'Update', eventAction: 'open_u_form' })}
    >
      <UpdateForm rootModal={updateModalName} />
    </Modal>
  )
}
