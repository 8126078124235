const pushToDataLayer = (obj: GAProps) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}

export const ga: GA = {
  event({ cat, action, label, ctx, code }: GAEventProps) {
    try {
      const p: GAProps = {
        event: 'GAEvent',
        eventCategory: cat,
        eventAction: action,
      }

      if (label) Object.assign(p, { eventLabel: label })
      if (ctx) Object.assign(p, { eventContext: ctx })
      if (code) Object.assign(p, { errorCode: code })

      if (process.env.REACT_APP_DEBUG === 'true') console.info('_GAEvent:', p)
      pushToDataLayer(p)
    } catch (e) {
      console.error('GA Event Error:', e)
    }
  },
}

export interface GA {
  event({ ...props }: GAEventProps): void
}

export interface GAProps {
  event?: string
  eventCategory: CATEGORY
  eventAction: ACTION
  eventLabel?: string
  eventContext?: string
  errorCode?: number
}

export interface GAEventProps {
  cat: CATEGORY
  action: ACTION
  label?: string
  ctx?: string
  code?: number
}

export type CATEGORY = 'Registration' | 'Authorization' | 'Add_social' | 'Main_page' | 'UpdateUser'

export type ACTION =
  | 'click_get_in'
  | 'open_r_form'
  | 'send_r_form'
  | 'send_u_form'
  | 'leave_registartion'
  | 'over_social_r'
  | 'over_social_a'
